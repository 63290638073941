<template>
  <div class="mr-4">
    <v-subheader>Job Infomation</v-subheader>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="jobInfo.telType"
          :items="telTypes"
          item-text="key"
          item-value="value"
          label="Tel Type"
          dense
          outlined
          required />
      </v-col>
      <v-col cols="8">
        <v-text-field
          label="Job Name"
          v-model="jobInfo.name"
          dense
          outlined
          required />
        <v-row>
          <v-col cols="6"
              class="mt-2">
            <v-menu
              v-model="jobDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  label="Date Start Job"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="jobDate = false" />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-switch
              label="Activate Job"
              color="info"
              v-model="jobInfo.activated"
              hide-details />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  name: 'CreateAutomateWelcomeJob',
  props: {
    jobInfo: {
      type: Object
    }
  },
  data () {
    return  {
      telTypes: [
        { key: 'ALL', value: '' },
        { key: 'POSTPAID', value: 'T' },
        { key: 'PREPAID', value: 'P' }
      ],
      openMap: false,
      date: moment().format('YYYY-MM-DD'),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      jobDate: false,
      jobTime: '',
      time: '08:00',
      ids: '',
      msisdn: '',
      showInfo: false,
      errorMessage: null,
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    date(val) {
      this.dateFormatted = this.formatDate(this.date);

      const dayStart = new Date(`${this.date}T${this.time}`);

      this.jobInfo.startTime = Date.parse(dayStart);
      this.jobInfo.endTime = this.jobInfo.startTime + (1000 * 10);
    },
  },

  computed: {
    computedDateFormatted() {
      this.jobInfo.dateJob = this.formatDate(this.date);
      return this.formatDate(this.date);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    setDescription(description) {
      this.description = description;
    }
  }
}
</script>

<style lang="scss" scoped>
  #map {
    width: 150vw;
    height: 50vw;
  }

  #searchPlace {
    width: 100%;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    font-size: small;
    font-weight: normal;
  }

  .file-wrapper {
    text-align: center;
    margin: auto auto;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    min-width: 270px;
    height: 150px;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    overflow: hidden; 
      input {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0.0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 200px;
      }

      .iconUpload {
        margin: auto auto;
      }
  }

  .fileList {
    color: #039BE5;
    list-style-type: none;
  }

</style>